import React, { Fragment, createContext, useReducer } from 'react';
import { LinearProgress } from '@material-ui/core';

export const PageBaseDispatch = createContext({});
 
//Takes care of all page generic functions and callbacks, like pop up messages and loading

export interface IPageBase {
    loaded: boolean
}

const PageBase: React.FC = (props) => {

    const pageBaseReducer = (state: IPageBase, action: any) => {
        switch (action.type) {
            case 'setLoaded':
                return { ...state, loaded: action.payload }
            default:
                return state;
        }
    };
    const [pageBase, dispatchPageBase] = useReducer(pageBaseReducer, { loaded: true });

    return (
        <Fragment>
            <PageBaseDispatch.Provider value={dispatchPageBase}>
                <LinearProgress style={{ position: 'relative', top: '50px' }} hidden={pageBase.loaded} />
                {props.children}
            </PageBaseDispatch.Provider>
        </Fragment>
    );
};

export default PageBase;
