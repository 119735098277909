import React, { useState, useEffect } from 'react'
import { TROAppObj } from '../../types';
import { makeStyles, Paper, Typography } from '@material-ui/core';
import TROApp from './TROApp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

const useStyles = makeStyles(theme => ({
    root: {
        position: "relative",

    },
    container: {
        marginLeft: 45,
        paddingLeft: 45,
        flexWrap: 'nowrap',
        overflow: 'hidden',
        height: 250,
        maxHeight: 250,
        display: 'flex',
        border: '20px solid white',
        borderBottomWidth: 5,
        paddingBottom: 30,
        paddingTop: 20,
        '&:hover': {
            overflow: 'auto',
        }
    },
    expandBtn: {
        padding: 8,
        marginBottom: 5,
        backgroundColor: 'white',
        borderRadius: 10,
        position: 'absolute',
        right: 0,
        bottom: 0
    },
    header: {
        fontWeight: 600,
        left: -38,
        top: 150,
        transform: "rotate(270deg)",
        position: 'absolute'
    }
}));

interface IProps {
    classes?: any
}

const TROAppDraw: React.FC<IProps> = (props) => {
    const classes = useStyles();
    const [expanded, setExpanded] = useState(false);
    const [Apps, setApps] = useState<TROAppObj[]>([]);

    useEffect(() => {
        if (Apps.length === 0) {
            setApps([
                { colour: '#2600f5b3', name: "TRO", description: 'TRO Website', imageUrl: process.env.PUBLIC_URL + '/Images/6.png', href: 'https://tro.com' },
                { colour: '#ffc800c7', name: "PO", description: 'Purcahse Orders', imageUrl: process.env.PUBLIC_URL + '/Images/9.png', href: 'https://purchaseorders.test.tro-group.co.uk' },
                { colour: '#29bf4294', name: "Intranet", description: 'Legacy Intranet', imageUrl: process.env.PUBLIC_URL + '/Images/4.png', href: 'http://intranet.tro-group.co.uk' },
            ]);
        }
    }, [Apps.length, setApps]);

    return (
        <div className={classes.root}>
            <div className={classes.header}>
                <Typography
                    component="span"
                    variant="h6"

                    color="textPrimary"
                >
                    App Drawer
                </Typography>
            </div>
            <Paper variant="outlined" className={classes.container}>
                {Apps.map((app, i) => (
                    <TROApp key={i} app={app} />
                ))}
            </Paper>
            <div onClick={() => { setExpanded(!expanded) }} className={classes.expandBtn}>
                {expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </div>
        </div>
    );

}

export default TROAppDraw

