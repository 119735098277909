import React, { useEffect } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';
import { TwitterTimelineEmbed } from 'react-twitter-embed';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import { useMediaQuery, Paper } from '@material-ui/core';
import useScript from '../../Hooks/useScript';
//const userInstagram = require("user-instagram"); 

// userInstagram("edouard_courty")
//     .then(console.log)
//     .catch(console.error);

interface IProps {
    children?: React.ReactNode;
    index: any;
    value: any; 
}

function TabPanel(props: IProps) {
    const { children, value, index, ...other } = props;
    useScript('https://connect.facebook.net/en_GB/sdk.js#xfbml=1&version=v7.0');

    return (
        <Typography 
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-prevent-tabpanel-${index}`}
            aria-labelledby={`scrollable-prevent-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}

function a11yProps(index: any) {
    return {
        id: `scrollable-prevent-tab-${index}`,
        'aria-controls': `scrollable-prevent-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        right: 0,
        overflow: 'hidden',
        width: 700,
        display: "flex",
        backgroundColor: 'transparent',
        '& ::-webkit-scrollbar': {
            width: "0px !important"
        },
        transition: 'width ease .5s',
        position: 'relative'
    },
    tabs: {
        minWidth: 80,
        width: 80,
        left: 0,
        backgroundColor: 'transparent',
        '& button': {
            minWidth: 'auto'
        },
        zIndex: 20,
    },
    content: {
        width: 420,
        minWidth: 420,
        backgroundColor: "white",
        overflowY: 'auto',
        height: "100%",
    },
    openCloseBtn: {
        width: "100%",
        textAlign: "center",
        paddingTop: 10,
        cursor: 'pointer'
    },
    facebook: {
        '& ._2p3a': {
            width: '100%'
        }
    }
}));

export const FeedContainer = React.memo((handleStateChange: any) => {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const matchesLarge = useMediaQuery((theme: any) => theme.breakpoints.down('md'));
    const [open, setOpen] = React.useState(!matchesLarge);
    const [loading, setLoading] = React.useState(true);

    console.log("FeedContainer");

    window.addEventListener('resize', () => { setOpen(!matchesLarge) });

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    useEffect(() => {
        if (!open && !loading) {
            setOpen(true);
        }
        setLoading(false);
        // eslint-disable-next-line
    }, [value]) 

    useEffect(() => {
        setOpen(!matchesLarge);
        // eslint-disable-next-line
    }, [loading])

    return (
        <div className={classes.root} style={open ? {} : { width: 60 }}>


            <Tabs
                className={classes.tabs}
                value={value}
                onChange={handleChange}
                scrollButtons="off"
                orientation="vertical"
                aria-label="socialFeed"
            >
                <Tab icon={<TwitterIcon />} aria-label="facebook" {...a11yProps(0)} />
                <Tab icon={<FacebookIcon />} aria-label="twitter" {...a11yProps(1)} />
                <Tab icon={<InstagramIcon />} aria-label="instagram" {...a11yProps(2)} />
                <div className={classes.openCloseBtn} onClick={() => setOpen(!open)}> {open ? <KeyboardArrowRightIcon /> : <KeyboardArrowLeftIcon />} </div>
            </Tabs>
            <Paper className={classes.content}> 
                <TabPanel value={value} index={0}>
                    <TwitterTimelineEmbed
                        sourceType="profile"
                        screenName="TROexperiential"
                    />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <iframe
                        title="facebookfeed"
                        src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FTROExperiential%2F&tabs=timeline&width=380&height=1000&small_header=true&adapt_container_width=true&hide_cover=true&show_facepile=false&appId"
                        width="380"
                        height="1000"
                        style={{ border: "none", overflow: "hidden" }} scrolling="no" allow="encrypted-media"></iframe>

                </TabPanel>
                <TabPanel value={value} index={2}>
                    
                </TabPanel>
            </Paper>
        </div>
    );
});