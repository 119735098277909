import React from 'react'
import TROAppDraw from '../TROApp/TROAppDraw';
import { makeStyles } from '@material-ui/core';
//import FeedContainer from '../../Components/Library/Feeds/FeedContainer';
import NewsFeed from '../../Components/Library/Feeds/NewsFeed';
import NewsFeedLatest from '../../Components/Library/Feeds/NewsFeedLatest';
import {FeedContainer} from 'src/Components/Library/Feeds/FeedContainer';

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        height: "100%",
        justifyContent: "space-between"
    },
    main: {
        width: '100%',
        float: 'left',
        overflowY: 'auto',
        display: "inline-block",
        transition: 'width ease .5s',
        '&::-webkit-scrollbar': {
            width: "0px !important"
        }
    },
    newsContainer: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    welcome: {
        padding: 8,
        fontWeight: 600,
        fontSize: 20,
    },
    date: {
        fontSize: 12,
        padding: "12px 8px 8px 8px"
    },
    welcomeContainer: {
        display: 'flex',
        justifyContext: 'space-between'
    }
}));

interface IProps {
    classes?: any
}

const Home: React.FC<IProps> = React.memo(() => {
    console.log("home render");
    const classes = useStyles();

    return (
        <div className={classes.root} style={{}}>
            <div className={classes.main}>
                <TROAppDraw />
                
                <div className={classes.newsContainer}>
                    <NewsFeed />
                    <NewsFeedLatest />
                </div>
            </div>
            <FeedContainer />
        </div>
    ); 
}); 

export default Home

