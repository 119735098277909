import React, { useState } from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Paper } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '50%',
            overflow: 'hidden', 
            position: 'relative',
            marginTop: 45,
            marginLeft: 40,
            marginBottom: 15,
            padding: 5
        },
        overlay: {
            transition: 'opacity 1s',
            padding: '50px 50px 50px 250px',
            lineHeight: 2,
            color: 'white',
            opacity: '0',
            background: '#00000091',
            position: "absolute",
            zIndex: 10,
            top: -5,
            left: -5,
            bottom: -5,
            right: -5
          
        },
        content: {
            height: 500,
            maxHeight: 500,
            marginLeft: 40,
            overflow: 'hidden',
            position: "relative"
           
        },
        inline: { 
            display: 'inline',
        },
        header: {
            fontWeight: 600,
            left: -25,
            top: 50,
            transform: "rotate(270deg)",
            position: 'absolute'
        },
        image: {
            minHeight: '100%',
            maxWidth: 'calc(100% + 150px)',
            width: 'auto',
            height: 'auto',
            position: 'relative',
            left: -140
        },
        info: {
            transition: 'background-color 1s',
            zIndex: 15,
            position: 'absolute',
            left: 15,
            bottom: 15,
            borderRadius: 15,
            backgroundColor: '#00000091',
            padding: 20,
            width: 170,
            height: 135,
            textAlign: "center",
            verticalAlign: 'middle'
        },
        title: {
            marginTop: 30,
            '& span': {
                fontWeight: 700,
                color: 'white'
            }
        },
        description: {
            marginTop: 40,
            color: 'grey',
            fontSize: 12
        }
    }),
);

interface IProps {

}

const NewsFeedLatest: React.FC<IProps> = (baseProps) => {
    const classes = useStyles();
    const [hoverState, setHoverState] = useState(false); 

    return (
        <div className={classes.root}>
            <div className={classes.header}>
                <Typography
                    component="span"
                    variant="h6"
                
                    color="textPrimary"
                >
                    Pitch Win!
                </Typography>
            </div>
            <Paper className={classes.content} onMouseEnter={() => { setHoverState(true) }} onMouseLeave={() => { setHoverState(false) }}>
                <div className={classes.overlay} style={hoverState ? { opacity: '1' } : {}}>
                       new #BMWi8 Roadster Safety Car livery launch 

                       Guest programme for 300 BMW guests 

                       Activating the new S6 #BMWiMotorsport E-Village stand

                       Over 200 BMW garage tour guests

                       R4 of the @FIAFormulaE in #MexicoCity with @BMWMotorsport - done! #NextStopMarrakesh #Experiential
                       
                       20/04/2020
                        
                </div>
                <img alt="img" className={classes.image} src={"1.jpg"} />
                <div className={classes.info} style={hoverState ? { backgroundColor: 'transparent' } : {}}>
                    <div className={classes.title}>
                        <Typography
                            component="span"
                            variant="h6"

                            color="textPrimary"
                        >
                            We won the BMW Pitch!!
                        </Typography>
                    </div>
                    
                </div>
            </Paper>
        </div>
    );
}

export default NewsFeedLatest