import React, { useContext } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { ThemeContext } from '../Context/ThemeContext';
import { SliderPicker  } from 'react-color';
import { makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    content: {
        minWidth: 400,
        textAlign: 'center'
    }
}));

interface IProps { 
    open: boolean,
    onClose: any
}
export interface Action {
    type: string
  }
  
  export interface ShowAllAction extends Action {
    payload: boolean
  }
   
  export interface SetStatusAction extends Action {
    payload: {
      index: number
      status: string
    }
  }
export const ThemePickerDialog: React.FC<IProps> = (props) => {
    const classes = useStyles();
    const { themeState, themeDispatch } = useContext(ThemeContext);
    
    const handleClose = () => {
        props.onClose();
    };

    const handleColorChange = (prop, color) => {
        console.log(color); 

        if(color.hex !== '#000000'){
            themeDispatch({type: 'edit-palette', payload: {[prop]: {main: color.hex}}})
        }
      };

    return (
        <Dialog open={props.open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Theme Picker</DialogTitle>
            <DialogContent className={classes.content}>
                <Typography variant="subtitle1" gutterBottom>
                    Primary
                </Typography>
                <SliderPicker 
                    color={ themeState.palette.primary.main }
                    onChangeComplete={ (color) => {handleColorChange('primary', color)} }
                />
                <br />
                <Typography variant="subtitle1" gutterBottom>
                    Secondary
                </Typography>
                <SliderPicker 
                    color={ themeState.palette.secondary.main }
                    onChangeComplete={ (color) => {handleColorChange('secondary', color)} }
                />
                <br />
              
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
} 