import React, { FC, useState, Fragment, useEffect } from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import HomeIcon from '@material-ui/icons/Home';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(1),
    },
    title: {
        flexGrow: 1,
    },
    offset: {
        ...theme.mixins.toolbar,
        flexGrow: 1
    },
    list: {
        width: 250,

    },
    link: {
        textDecoration: 'none',
        color: "inherit"
    },
    drawerFixed: {
        marginTop: 40,
        float: 'left',
        borderRight: '1px solid #d1d1d1',
        height: '100%',
        overflow: 'hidden',
        width: '250px'
    }
}));

interface IProps {
    open: boolean,
    onClose: any,
    setTitle: any
}

export const TRODrawer: FC<IProps> = ({ open = false, onClose = () => { }, setTitle = (title: string) => { } }: IProps) => {

    const classes = useStyles();
    const [drawerOpen, setDrawerOpen] = useState(false);

    const matches = useMediaQuery((theme: any) => theme.breakpoints.down('lg'));

    const toggleDrawer = (open: boolean) => (event: any) => {
        if (!matches) {
            return
        }

        if (
            event &&
            event.type === "keydown" &&
            (event.key === "Tab" || event.key === "Shift")
        ) {
            return;
        }

        setDrawerOpen(open);
        console.log(event);
        onClose();
    };

    useEffect(() => {
        setDrawerOpen(open);
    }, [open]);


    const sideList = () => (
        <div
            className={classes.list}
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
        >
            <List style={{ padding: 4 }}>
                <Link to='/' className={classes.link}>
                    <ListItem button onClick={() => setTitle("Home")} key={"Home"}>
                        <ListItemIcon>
                            <HomeIcon />
                        </ListItemIcon>
                        <ListItemText primary={"Home"} />
                    </ListItem>
                </Link>
            </List>
            <Divider />
        </div>
    );

    const drawer = matches ?
        <SwipeableDrawer
            open={drawerOpen}
            onClose={toggleDrawer(false)}
            onOpen={toggleDrawer(true)}
        >
            {sideList()}
        </SwipeableDrawer>
        :
        <div className={classes.drawerFixed} style={drawerOpen ? {} : { display: 'none' }}>
            {sideList()}
        </div>;

    return (
        <Fragment>
            {drawer}
        </Fragment>
    );
}

