import React, { createContext, useReducer, ReactElement } from 'react';
import { User } from 'oidc-client';

const initialState: any = false;
const AuthContext = createContext(initialState);

const { Provider } = AuthContext;

const AuthProvider = (props: { children: ReactElement }) => {

    const [authState, authDispatch] = useReducer((state: User, action: any) => {
        switch (action.type) {
            case "set":
                return action.payload;
            case "clear":
                return null;
            default:
                return state;
        }
    }, false);

   
    return (
        <Provider value={{ authState, authDispatch }}>
            {props.children}
        </Provider>
    );
};

export {
    AuthContext,
    AuthProvider
}
