import React, { useState } from 'react'
import { TROAppObj } from '../../types';
import { makeStyles, Paper } from '@material-ui/core';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        perspective: 1000,
        width: 200,
        minWidth: 200,
        height: 200,
        minHeight: 200,
        margin: 20,
        right: 20,
        position: 'relative',
        display: 'inline-block',
        textAlign: "center",
        cursor: 'pointer'
    },
    overlay: {
        background: '#00000091',
        position: "absolute",
        zIndex: 10,
        top: 0,
        left: 0,
        bottom: 0,
        right: 0

    },
    flipCardInner: {
        position: "relative",
        width: '100%',
        height: '100%',
    },
    flipCardFront: {
        width: '100%',
        transition: 'transform 0.6s',
        transformStyle: 'preserve-3d',
        position: "absolute",
        height: '100%',
        backfaceVisibility: 'hidden'
    },
    flipCardBack: {
        transition: 'transform 0.6s',
        transformStyle: 'preserve-3d',
        width: '100%',
        position: "absolute",
        height: '100%',
        backfaceVisibility: 'hidden',
        transform: 'rotateY(180deg)'
    },
    frontCard: {
        transition: 'transform 0.6s',
        transformStyle: 'preserve-3d',
    },
    container: {
        color: 'white',
        minHeight: 200,
    },
    icon: {
        minWidth: '10%',
        maxWidth: '120px',
        margin: 30
    },
    name: {
        position: "absolute",
        bottom: 5,
        width: 200,
        fontWeight: 600,
        fontSize: 16,
    },
    description: {
        color: '#b3b3b3',
        fontStyle: 'italic',
        
    }
}));

interface IProps {
    classes?: any,
    app: TROAppObj
}

const TROApp: React.FC<IProps> = (props) => {
    const classes = useStyles();
    const [hoverState, setHoverState] = useState(false);

    return (
        <a href={props.app.href}>
            <div className={classes.root} onMouseEnter={() => { setHoverState(true) }} onMouseLeave={() => { setHoverState(false) }}>
                <div className={classes.flipCardInner}  >
                    <div className={classes.flipCardFront} style={hoverState ? { transform: 'rotateY(180deg)' } : {}}>
                        <Paper className={classes.container} style={{ backgroundColor: props.app.colour }}>
                            <img alt={"TRO App"} className={classes.icon} src={props.app.imageUrl} />
                            <Typography className={classes.name} variant="h6" gutterBottom>
                                {props.app.name}
                            </Typography>
                        </Paper>
                    </div>
                    <div className={classes.flipCardBack} style={hoverState ? { transform: 'rotateY(360deg)' } : {}}>
                        <Paper className={classes.container} style={{ backgroundColor: props.app.colour }}>
                            <Typography className={classes.name} variant="h6" gutterBottom>
                                {props.app.description}
                            </Typography>
                        </Paper>
                    </div>
                </div>
            </div>
        </a>
	);
    
}

export default TROApp

