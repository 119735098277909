import React, { createContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';

import './App.css';
import Main from './Components/PageBase/Main';
import PageBase from './Components/PageBase/PageBase';
import { TROAppBar } from './Components/PageBase/AppBar';


const useStyles = makeStyles(theme => ({
    root: {
        height: "calc(100% - 120px)", ///> - 100 for padding + app bar height - should find how to retrieve as can change
        padding: 10,
        paddingTop: 30
    },
    appContent: {
        height: "100%",
        marginTop: 68,
        padding: 8
    }
}));

export const LoadingDispatch = createContext({});
export const MediaQueryContext = createContext({});


const App: React.FC = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <PageBase>
                <TROAppBar />
                <div className={classes.appContent}>
                    <Main  /> {/*styles={{ width: mediaQuery.l || (!mediaQuery.l && !drawerOpen) ? '100%' : 'calc(100% - 252px)' }} */}
                </div>
            </PageBase>
        </div>
    );
};

export default withRouter(App);
