import React from 'react';
import { Route } from 'react-router-dom';
import { makeAuthenticator } from 'react-oidc'
import { userManager } from 'src/typesTemplates';


function PrivateRoute({ component: Component, ...rest }) {

    const CheckedComponent = makeAuthenticator({
        userManager: userManager,
        signinArgs: {
            // state: {
            //     foo: 15 ///> for testing parsed props over login redirect
            // }
        }
    })(Component);

    return <Route {...rest} render={(props) => <CheckedComponent /> }/>
}

export default PrivateRoute;



