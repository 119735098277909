import { FC, useState, Fragment } from "react";
import { AppBar, Toolbar, IconButton, Typography, TextField, InputAdornment, Button, makeStyles, useMediaQuery } from "@material-ui/core";
import React from "react";
import { userManager } from "src/typesTemplates";
import { MediaQuery } from "src/types";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { ThemePickerDialog } from "../Dialog/ThemePickerDialog";
import MenuIcon from '@material-ui/icons/Menu';
import StyleIcon from '@material-ui/icons/Style';
import SearchIcon from '@material-ui/icons/Search';
import { TRODrawer } from "./Drawer";

const useStyles = makeStyles(theme => ({
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: { 
        
    },
    searchContainer: {
        marginLeft: 25,
        marginRight: 25,
        height: '100%',
        maxWidth: 300,
        borderRadius: 50,
        background: '#f4f4e845'
    },
    search: {
        marginLeft: 20,
        marginTop: -13,
        maxWidth: 300,
        marginRight: 10,
        '& .MuiInput-underline:before ': {
            borderColor: '#4e4e4e',
            borderBottomStyle: 'dotted',
        },
        '& .MuiInput-underline:after ': { 
            borderColor: 'grey',
        }
    },
    searchInput: {
        color: 'white !important'
    },
    searchInputLabel: {
        display: 'none',
        color: 'transparent'
    },
    offset: {
        ...theme.mixins.toolbar,
        flexGrow: 1
    },
    main: {

    }
}));


export const TROAppBar = () => {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [title, setTitle] = useState("TRO");
    const [themeDialogOpen, setThemeDialogOpen] = useState(false);
    const classes = useStyles(); 

    var mediaQuery: MediaQuery = {
        l: useMediaQuery((theme: any) => theme.breakpoints.down('lg')),
        m: useMediaQuery((theme: any) => theme.breakpoints.down('md')),
        s: useMediaQuery((theme: any) => theme.breakpoints.down('sm'))
    }

    window.addEventListener('resize', () => { setDrawerOpen(!mediaQuery.l) });

    const toggleDrawer = (open: boolean) => (event: any) => {
        if (
            event &&
            event.type === "keydown" &&
            (event.key === "Tab" || event.key === "Shift")
        ) {
            return;
        }
        setDrawerOpen(open);
    };

    return (
        <Fragment>
            <AppBar position="fixed">
                <Toolbar>
                    <IconButton onClick={toggleDrawer(!drawerOpen)} edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
                        {mediaQuery.l ? <MenuIcon /> : drawerOpen ? <ArrowBackIosIcon /> : <ArrowForwardIosIcon />}
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        {title}
                    </Typography>
                    <div style={{ flexGrow: 1 }}>
                        <div className={classes.searchContainer}>
                            <TextField
                                className={classes.search}
                                id="standard-basic"
                                InputLabelProps={{
                                    className: classes.searchInputLabel
                                }}
                                InputProps={{
                                    disableUnderline: true,
                                    className: classes.searchInput,
                                    startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>
                                }}
                                    label="Search" />
                        </div>
                    </div>
                    <StyleIcon style={{ marginRight: 20 }} onClick={() => { setThemeDialogOpen(true) }} />
                    <Button onClick={() => { userManager.signoutRedirect() }} color="inherit">Log Out</Button>
                </Toolbar>
            </AppBar>
            <TRODrawer
                setTitle={(title: string) => setTitle(title)}
                open={drawerOpen}
                onClose={() => setDrawerOpen(false)} />
            <ThemePickerDialog open={themeDialogOpen} onClose={() => { setThemeDialogOpen(false) }} />
        </Fragment>
    );
}