import React  from "react";
import { makeStyles, Typography } from '@material-ui/core';
import PlayArrowOutlinedIcon from '@material-ui/icons/PlayArrowOutlined';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        height: 'calc(100vh - 280px)'
    },
    logo: {
        width: 200,
        margin: 30
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        position: 'relative'
    },
    link: {
        cursor: 'pointer',
        '&:hover': {
            opacity: '0.7'
        },
    },
    linkText: {
        textAlign: 'center',
        marginRight: 20,
        marginTop: 5
    },
    loginBtn: {
        fontSize: '23pt',
        position: 'absolute',
        right: 50,
        bottom: 0
    }
}));
 
const LoggedOut: React.FC = () => {

    const classes = useStyles();
    const history = useHistory();

    return (
        <div className={classes.root}>

            <img alt="logo" src={"/Images/logo.png"} className={classes.logo}/>
            <form className={classes.form}>
                <Typography variant="h5" gutterBottom>
                    You've been logged out
                </Typography>
                <div className={classes.link} onClick={() => {history.push("/")}}>
                    <Typography variant="subtitle2" gutterBottom className={classes.linkText}>
                        Click to login again
                    </Typography>
                    <PlayArrowOutlinedIcon 
                        className={classes.loginBtn}
                    />
                </div>
            </form>
        </div>
    );
}

export default LoggedOut