import { blue } from "@material-ui/core/colors";
import { CustomTheme } from "./types";
import './fonts.css'
import { makeUserManager } from "react-oidc";

export const userManager = makeUserManager({
    authority: process.env.REACT_APP_IDENTITY_AUTHORITY,
    client_id: 'PlatformWeb',
    redirect_uri: process.env.REACT_APP_IDENTITY_REDIRECT_URI,
    post_logout_redirect_uri: process.env.REACT_APP_IDENTITY_POST_LOGOUT_REDIRECT,
    response_type: 'code',
    scope: 'openid profile email TestingKestrel.access'
})

export const CustomThemeTemplate: CustomTheme = {
    palette: {
        primary: { main: '#292f3e' },
        secondary: blue
    },
    dialog: {
        minWidth: 300
    },
    typography: {
        fontFamily: [
            'Homeday',
        ].join(',')
    }
};

