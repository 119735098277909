import React, { useContext, useEffect } from 'react'
import { Switch, Route } from 'react-router-dom'
import Home from '../../Screens/Home/Home'
import PrivateRoute from '../Routes/PrivateRoute';
import Admin from 'src/Screens/Admin/Admin';
import { Callback } from 'react-oidc'
import { userManager } from 'src/typesTemplates';
import LoggedOut from 'src/Screens/LoggedOut/LoggedOut';
import { AuthContext } from '../Context/AuthContext';

interface IProps {
}


const Main: React.FC<IProps> = (baseProps) => {

    const { authDispatch } = useContext(AuthContext);

    useEffect(() => {
        const fetchUser = async () => {
            const user = await userManager.getUser();
            authDispatch({type: 'set', payload: user});
        }
        fetchUser();
    }, [authDispatch])

    return (
        <main>
            <Switch>
                <Route
                    path="/callback"
                    render={routeProps => ( 
                    <Callback
                        onSuccess={user => {
                            authDispatch({type: 'set', payload: user});
                        // `user.state` will reflect the state that was passed in via signinArgs.
                            routeProps.history.push('/')
                        }}
                        userManager={userManager}
                    />
                    )}
                />
                <Route
                    path="/loggedout"
                    render={routeProps => (<LoggedOut/>)}
                />
                <PrivateRoute path='/' component={Home} />
                <PrivateRoute path='/admin' component={Admin} />
            </Switch>
        </main>
    ); 
};

export default Main